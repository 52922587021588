/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { motion, useAnimation, AnimatePresence } from 'framer-motion'
import { useStaticQuery, graphql, navigate, Link } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
// import Search from 'img/icon/search.inline.svg'
import search from 'img/icon/search.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import ButtonDefault from 'components/elements/ButtonDefault'

const StyledHeader = styled.nav`
  background-color: ${(props) => props.theme.color.face.light};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;

  @media (min-width: 992px) {
    height: ${(props) => (props.showActionBanner === 't' ? 135 : 100)}px;
  }

  @media (max-width: 991px) {
    height: ${(props) => (props.showActionBanner === 't' ? 95 : 60)}px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) =>
    props.showActionBanner === 't' ? 'calc(100% - 35px)' : '100%'};
`

const Brand = styled(CustomLink)`
  & > svg {
    height: auto;
    width: 230px;

    @media screen and (max-width: 991px) {
      height: auto;
      width: 130px;
    }
  }
`

const NavButton = styled.button`
  width: 50px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 50;
  position: relative;
  right: -3px;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

const Topper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;

  a {
    &:hover {
      color: ${(props) => props.theme.color.text.secondary};
    }
  }
`

const InputWrapper = styled.div`
  position: relative;
`

const Input = styled.input`
  background: ${(props) => props.theme.color.face.light};
  border-radius: 29.5px;
  z-index: 2;
  position: relative;
  border: none;
  padding: 0 10px 0 30px;
  max-width: 157px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-size: ${(props) => props.theme.font.size.s};
  }

  @media screen and (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.xm};
    max-width: 166px;
    padding: 5px 0 5px 0;
    text-align: center;
    font-weight: ${(props) => props.theme.font.weight.xl};

    &::placeholder {
      text-align: center;
      padding: 0;
      font-size: ${(props) => props.theme.font.size.xm};
      font-weight: ${(props) => props.theme.font.weight.xl};
    }
  }
`

const InputShadow = styled.div`
  position: absolute;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  top: -2px;
  left: -2px;
  z-index: 1;
  border-radius: 29.5px;
  background: ${(props) => props.theme.color.gradient.main};
`

const StyledSearch = styled.img`
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3;

  @media screen and (max-width: 991px) {
    width: 24px;
    height: 24px;
  }
`

const GradientBar = styled(motion.div)`
  width: 100%;
  background: ${(props) => props.theme.color.gradient.main};
`

const ActionBanner = styled.div`
  background: ${({ theme }) => theme.color.gradient.main};
  color: ${({ theme }) => theme.color.text.light};
  font-size: ${({ theme }) => theme.font.size.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  position: relative;

  & > a {
    font-weight: ${({ theme }) => theme.font.weight.l};
    text-decoration: underline;

    & > svg {
      width: 15px;
      height: 15px;
      margin-left: 0.25rem;
    }
  }
`

const ActionBannerClose = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 3px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2px;

  &:hover {
    background-color: #0fa6b7;
  }

  & svg {
    width: 22px;
    height: 22px;
    stroke: ${({ theme }) => theme.color.text.light};
  }

  @media (min-width: 992px) {
    right: 15px;
  }

  @media (max-width: 991px) {
    right: 5px;
  }
`

const Header = ({ isSticky, showActionBanner, setShowActionBanner }) => {
  const { menuItems, header } = useStaticQuery(graphql`
    {
      menuItems: wordpressMenusMenusItems(wordpress_id: { eq: 2 }) {
        ...HeaderMenuFrag
      }

      header: wordpressWpComponenten(wordpress_id: { eq: 97 }) {
        ...HeaderFrag
      }
    }
  `)

  const searchForIt = (event) => {
    if (event.keyCode === 13) {
      navigate(`/zoeken?query=${event.target.value}`)
    }
  }

  return (
    <StyledHeader showActionBanner={showActionBanner} id="header">
      {showActionBanner === 't' && (
        <ActionBanner>
          <span className="d-lg-block d-none">
            <strong>Nieuws</strong>: onze postcodecheck is live! Bekijk zelf wat
            er mogelijk is op uw locatie.
          </span>
          <span className="d-lg-none">Onze postcodecheck is live!</span>
          <Link
            to="/offerte-aanvragen/"
            className="ml-lg-3 ml-2 d-flex align-items-center"
          >
            <span className="d-lg-block d-none">Meer informatie</span>
            <span className="d-lg-none">Meer</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-right"
            >
              <line x1={5} y1={12} x2={19} y2={12} />
              <polyline points="12 5 19 12 12 19" />
            </svg>
          </Link>
          <ActionBannerClose
            type="button"
            onClick={() => {
              setShowActionBanner('f')
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x"
            >
              <line x1={18} y1={6} x2={6} y2={18} />
              <line x1={6} y1={6} x2={18} y2={18} />
            </svg>
          </ActionBannerClose>
        </ActionBanner>
      )}
      <Container
        className="container position-relative"
        showActionBanner={showActionBanner}
      >
        <Topper className="d-none py-2 d-lg-flex align-items-center justify-content-end">
          <div className="mr-3">
            <a
              className="font-size-s color-secondary font-weight-xl"
              href={`tel:${header.acf.menu.phone}`}
            >
              {header.acf.menu.phone}
            </a>
          </div>
          <div className="mr-3">
            <InputWrapper>
              <Input onKeyDown={(e) => searchForIt(e)} placeholder="Zoeken" />
              <StyledSearch src={search} alt="" />
              <InputShadow />
            </InputWrapper>
          </div>
          <div>
            <ButtonDefault to="/klantportaal/" small icon="user">
              <p className="font-size-s mb-0">Klantportaal</p>
            </ButtonDefault>
          </div>
        </Topper>
        <Brand to="/">
          <Logo />
        </Brand>

        <MenuMobile items={menuItems} />
        <MenuDesktop items={menuItems} />
      </Container>
      <GradientBar
        initial={{ height: '0px' }}
        animate={isSticky ? { height: '4px' } : { height: '0px' }}
        exit={{ height: '0px' }}
        transition={{ duration: 0.2 }}
      />
    </StyledHeader>
  )
}

const MenuWrapper = styled(motion.div)`
  background: ${(props) => props.theme.color.gradient.secondary};
  padding: 30px 0 0;

  & > ul {
    padding: 0;
    list-style-type: none;
    text-align: center;
    width: 70%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: ${(props) => props.theme.font.size.l};
        color: ${(props) => props.theme.color.text.light};
        display: block;
        padding: 5px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding: 0;
    display: flex;
    align-items: center;
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.light};
    position: absolute;
    content: '';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const MenuButtonMobile = styled.div`
  a {
    padding: 4px 0 !important;
  }
`

const MenuMobile = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false)

  const controls = useAnimation()

  useEffect(() => {
    if (isOpen) {
      controls.start({
        position: 'fixed',
        height: '100vh',
        borderRadius: '0px',
        width: '100vw',
        right: '0',
        top: '0',
        zIndex: 99,
      })
    } else {
      controls.start({
        position: 'absolute',
        height: '45px',
        width: '45px',
        borderRadius: '50px',
        right: '0',
        top: '3px',
        zIndex: 49,
      })
    }
  }, [isOpen])

  const searchForIt = (event) => {
    if (event.keyCode === 13) {
      navigate(`/zoeken?query=${event.target.value}`)
    }
  }

  return (
    <div className="d-block position-relative d-lg-none">
      <NavButton role="button" onClick={() => setIsOpen(!isOpen)}>
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      <MenuWrapper
        initial={{
          height: '40px',
          width: '40px',
          borderRadius: '50px',
          top: '8px',
        }}
        animate={controls}
        transition={{
          duration: 0.2,
        }}
      >
        {isOpen && (
          <>
            <NavButtonClose role="button" onClick={() => setIsOpen(!isOpen)}>
              <NavButtonCloseCross />
            </NavButtonClose>
            <ul>
              <Menu items={items} />
              <li className="py-3">
                <div className="d-flex justify-content-center">
                  <InputWrapper>
                    <Input
                      onKeyDown={(e) => searchForIt(e)}
                      placeholder="Zoeken"
                    />
                    {/* <StyledSearch /> */}
                    <StyledSearch src={search} alt="" />
                    <InputShadow />
                  </InputWrapper>
                </div>
              </li>
              <li className="py-3">
                <MenuButtonMobile>
                  <ButtonDefault
                    className="py-0"
                    external
                    newPage
                    to="https://account.onebase.io/Account/Logon?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DOneBase%26redirect_uri%3Dhttps%253A%252F%252Fonebase.io%252F%26response_type%3Did_token%2520token%26scope%3Dopenid%2520profile%2520ROSA%2520ROSABackend%26nonce%3D9c7516e045fb48c95795605c95a3601dacpRoaQOK%26state%3D274ef824862f1269664e5c590c7845a25f4XcBphQ"
                    icon="user"
                  >
                    <p className="mb-0 pl-5 pr-4">Inloggen</p>
                  </ButtonDefault>
                </MenuButtonMobile>
              </li>
            </ul>
          </>
        )}
      </MenuWrapper>
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`

const MenuDesktop = ({ items }) => (
  <StyledMenuDesktop className="d-none pt-3 d-lg-flex">
    <Menu items={items} />
  </StyledMenuDesktop>
)

const Item = styled(motion.li)`
  position: relative;

  a {
    color: ${(props) => props.theme.color.text.main};
    font-weight: ${(props) => props.theme.font.weight.xl};
    position: relative;
    float: left;

    &[aria-current] {
      &::before {
        content: '';
        position: absolute;
        width: 110%;
        height: 3px;
        background: ${(props) => props.theme.color.gradient.main};
        bottom: -9px;
        left: -5%;

        @media screen and (max-width: 991px) {
          background: ${(props) => props.theme.color.face.light};
          bottom: 5px;
        }
      }

      @media screen and (max-width: 991px) {
        color: ${(props) => props.theme.color.gradient.main};
      }
    }

    &:hover {
      color: ${(props) => props.theme.color.text.main};
      text-decoration: none;
    }
  }
`

const Underline = styled(motion.div)`
  position: absolute;

  height: 3px;
  background: ${(props) => props.theme.color.gradient.main};
  bottom: -8px;
  left: -5%;
`

const Menu = ({ items }) => {
  const [open, setOpen] = React.useState(null)

  return (
    <>
      {items.items.map(({ url, title, child_items }, index) => (
        <Item
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          initial="rest"
          whileHover="hover"
          animate="rest"
          onMouseEnter={() => {
            if (index === open) {
              return
            }

            setOpen(index)
          }}
          onMouseLeave={() => {
            setOpen(null)
          }}
        >
          <div className="d-flex justify-content-center position-relative">
            <CustomLink to={url}>{title}</CustomLink>
            <Underline
              variants={{
                rest: {
                  opacity: 0,
                  width: 0,
                },
                hover: {
                  opacity: 1,
                  width: '110%',
                },
              }}
            />
            {child_items && child_items.length > 0 && (
              <AnimatePresence>
                {open === index && <SubMenu items={child_items} />}
              </AnimatePresence>
            )}
          </div>
        </Item>
      ))}
    </>
  )
}

const StyledSubMenu = styled(motion.div)`
  background-color: ${({ theme }) => theme.color.face.light};
  border-radius: 15px;
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  width: 200px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 10;

  & a:hover {
    background-color: ${({ theme }) => theme.color.face.lightBorder};
  }

  & a:before {
    content: none !important;
  }

  @media (max-width: 991px) {
    & > a {
      color: #000 !important;
      font-size: 14px !important;
    }
  }
`

const SubMenuSanatize = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 20px;
  z-index: 1;
`

const SubMenu = ({ items }) => {
  return (
    <StyledSubMenu
      initial={{
        y: 15,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
      exit={{
        y: 15,
        opacity: 0,
      }}
      className="py-1"
    >
      <SubMenuSanatize />
      {items.map(({ url, title }) => (
        <CustomLink to={url} key={url} className="py-2 px-3 w-100">
          {title}
        </CustomLink>
      ))}
    </StyledSubMenu>
  )
}

export default Header
