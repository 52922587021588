/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Facebook from 'img/icon/clearmind_facebook.inline.svg'
import Linkedin from 'img/icon/linkedin_clearmind.inline.svg'
// import Twitter from 'img/icon/twitter_clearmind.inline.svg'
import twitter from 'img/icon/twitter_clearmind.svg'
import Logo from 'img/logo.inline.svg'
import Future from 'img/building-our-future.png'

// Components
import CustomLink from 'components/shared/CustomLink'
import GravityForm from 'components/GravityForm'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.face.secondary};
  color: ${(props) => props.theme.color.text.secondary};

  h2 {
    font-size: ${(props) => props.theme.font.size.mx};
    font-weight: ${(props) => props.theme.font.weight.xl};
    padding-bottom: 10px;
  }

  p {
    color: ${(props) => props.theme.color.text.main};
  }
`

const Li = styled.li``

const Ul = styled.ul`
  list-style: none;
  padding-left: 0;
`

const StyledCustomLink = styled(CustomLink)`
  color: ${(props) => props.theme.color.text.main};
`

const SoftP = styled.div`
  color: ${(props) => props.theme.color.text.soft};
`

const Topper = styled.div`
  h2 {
    font-size: ${(props) => props.theme.font.size.xl};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
  }
`

const TopperContent = styled.div`
  padding-left: 200px;

  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
`

const FormContainer = styled(motion.div)`
  background: ${(props) => props.theme.color.gradient.main};
  height: 84px;
  border-top-left-radius: 84px;
  border-bottom-left-radius: 84px;
  width: 200%;
  position: relative;
  bottom: -25px;
  left: 550px;

  @media screen and (max-width: 991px) {
    left: 0;
  }
`

const IconWrapper = styled(motion.div)`
  height: 26px;
  width: 26px;
`

const Footer = ({ spacingBottom = false }) => {
  const {
    themes: { edges: themes },
    solutions: { edges: solutions },
    branches: { edges: branches },
    information: { acf },
  } = useStaticQuery(graphql`
    {
      information: wordpressWpComponenten(wordpress_id: { eq: 98 }) {
        ...FooterFrag
      }
      solutions: allWordpressPage(
        filter: { parent_element: { wordpress_id: { eq: 2535 } } }
        sort: { fields: date }
      ) {
        edges {
          node {
            ...LinksFrag
          }
        }
      }
      themes: allWordpressPage(
        filter: { parent_element: { wordpress_id: { eq: 110 } } }
        sort: { fields: date }
      ) {
        edges {
          node {
            ...LinksFrag
          }
        }
      }
      branches: allWordpressPage(
        filter: { parent_element: { wordpress_id: { eq: 112 } } }
        sort: { fields: date }
      ) {
        edges {
          node {
            ...LinksFrag
          }
        }
      }
    }
  `)

  const [ref, isVisible] = useInView({
    threshold: 0.2,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <>
      <Topper>
        <div className="container">
          <TopperContent className="pt-lg-5">
            {parse(acf.newsletter.description)}
          </TopperContent>
          <FormContainer
            ref={ref}
            className="d-flex align-items-center"
            initial={{ x: 50 }}
            animate={isVisible ? { x: 0 } : {}}
            transition={{ duration: 1, type: 'spring' }}
          >
            <GravityForm id={2} newsletter />
          </FormContainer>
        </div>
      </Topper>
      <StyledFooter className="pt-5">
        <div className="container px-lg-0 px-5 py-5">
          <div className="row">
            <div className="col-lg-2 pb-lg-0 pb-4">
              <h2>Thema's</h2>
              <Ul>
                {themes.map(({ node: theme }) => (
                  <Li key={theme.wordpress_id}>
                    <StyledCustomLink to={theme.path}>
                      <p className="mb-2">{theme.title}</p>
                    </StyledCustomLink>
                  </Li>
                ))}
              </Ul>
            </div>
            <div className="col-lg-2 pb-lg-0 pb-4">
              <h2>Oplossingen</h2>
              <Ul>
                {solutions.map(({ node: solution }) => (
                  <Li key={solution.wordpress_id}>
                    <StyledCustomLink to={solution.path}>
                      <p className="mb-2">{solution.title}</p>
                    </StyledCustomLink>
                  </Li>
                ))}
              </Ul>
            </div>
            <div className="col-lg-2 pb-lg-0 pb-4">
              <h2>Branches</h2>
              <Ul>
                {branches.map(({ node: branch }) => (
                  <Li key={branch.wordpress_id}>
                    <StyledCustomLink to={branch.path}>
                      <p className="mb-2">{branch.title}</p>
                    </StyledCustomLink>
                  </Li>
                ))}
              </Ul>
            </div>
            <div className="col-lg-3 pb-lg-0 pb-4">
              <h2>Support</h2>
              <Ul>
                <Li>
                  <StyledCustomLink
                    external
                    newPage
                    to={`tel:${acf.support.phonenumber}`}
                  >
                    <p className="mb-2">{acf.support.phonenumber}</p>
                  </StyledCustomLink>
                </Li>
                <Li className="pb-5">
                  <StyledCustomLink
                    external
                    newPage
                    to={`mailto:${acf.support.email}`}
                  >
                    <p className="mb-2 color-contrast font-weight-xl">
                      {acf.support.email}
                    </p>
                  </StyledCustomLink>
                </Li>
                <Li className="d-flex">
                  <StyledCustomLink
                    external
                    newPage
                    className="mr-2"
                    to={acf.support.facebook}
                  >
                    <IconWrapper
                      whileHover={{ rotate: [0, -20, 20, 0] }}
                      transition={{ duration: 0.3 }}
                    >
                      <Facebook />
                    </IconWrapper>
                  </StyledCustomLink>
                  <StyledCustomLink
                    external
                    newPage
                    className="mr-2"
                    to={acf.support.linkedin}
                  >
                    <IconWrapper
                      whileHover={{ rotate: [0, -20, 20, 0] }}
                      transition={{ duration: 0.3 }}
                    >
                      <Linkedin />
                    </IconWrapper>
                  </StyledCustomLink>
                  <StyledCustomLink external newPage to={acf.support.twitter}>
                    <IconWrapper
                      whileHover={{ rotate: [0, -20, 20, 0] }}
                      transition={{ duration: 0.3 }}
                    >
                      <img src={twitter} alt="" />
                    </IconWrapper>
                  </StyledCustomLink>
                </Li>
              </Ul>
            </div>
            <div className="col-lg-3 d-flex justify-content-start justify-content-lg-end">
              <div>
                <div className="d-flex justify-content-start justify-content-lg-end">
                  <Logo className="mb-4" />
                </div>
                {acf.address.offices.map((office, index) => (
                  <div key={index}>
                    <p className="mb-0 text-left text-lg-right">
                      {office.post}
                    </p>
                    <p className="text-left text-lg-right">{office.address}</p>
                  </div>
                ))}
                <p className="font-size-s">{acf.address.openingtimes}</p>
                <a
                  href="https://your.cloud/company/clear-mind/"
                  target="_blank"
                  className="d-block text-lg-right"
                >
                  <img src={Future} width="100" />
                </a>
              </div>
            </div>
            <div className="col-lg-12 pt-4 d-flex flex-wrap">
              <StyledCustomLink to="/algemene-voorwaarden/" className="mr-5">
                <SoftP className="mb-0">Algemene Voorwaarden</SoftP>
              </StyledCustomLink>
              <StyledCustomLink to="/cookieverklaring/" className="mr-5">
                <SoftP className="mb-0">Cookieverklaring</SoftP>
              </StyledCustomLink>
              <StyledCustomLink to="/privacyverklaring/" className="mr-5">
                <SoftP className="mb-0">Privacyverklaring</SoftP>
              </StyledCustomLink>
              <StyledCustomLink to="/faq/" className="mr-5">
                <SoftP className="mb-0">Veelgestelde vragen</SoftP>
              </StyledCustomLink>
              <StyledCustomLink to="/vacatures/">
                <SoftP className="mb-0">Vacatures</SoftP>
              </StyledCustomLink>
            </div>
          </div>
        </div>
        {spacingBottom && <div className="py-5" />}
      </StyledFooter>
    </>
  )
}

export default Footer
